import axios from 'axios'

export function getAllProvincia () {
  return axios.get('/maestros/provincias')
  .then(response => {
    return response.data
  })
}

export function updateProvincia (id, provincia) {
  return axios.put('/maestros/provincias/' + id, provincia)
  .then(response => {
      return response.data
    })
}

export function addProvincia (provincia) {
  return axios.post('/maestros/provincias', provincia)
  .then(response => {
    return response.data
  })
}

export function deleteProvincia (id) {
  return axios.delete('/maestros/provincias/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneProvincia (id) {
    return axios.get('/maestros/provincias/' + id)
    .then(response => {
        return response.data
      })
}

export function getProvinciasByPais (id) {
  return axios.get('/maestros/provincias/pais/' + id)
  .then(response => {
      return response.data
    })
}

export function getLogsProvincia (id) {
  return axios.get('/maestros/provincias/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllProvincia,
  updateProvincia,
  addProvincia,
  deleteProvincia,
  getOneProvincia,
  getProvinciasByPais,
  getLogsProvincia,
}
